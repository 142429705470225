// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\templates\\news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-team-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\templates\\team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-testimonial-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\templates\\testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-pages-404-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-casestudies-enhancing-the-supply-chain-in-premium-beef-production-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\casestudies\\enhancing-the-supply-chain-in-premium-beef-production.js" /* webpackChunkName: "component---src-pages-casestudies-enhancing-the-supply-chain-in-premium-beef-production-js" */),
  "component---src-pages-casestudies-index-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\casestudies\\index.js" /* webpackChunkName: "component---src-pages-casestudies-index-js" */),
  "component---src-pages-casestudies-managing-invoicing-and-early-payments-in-the-telco-industry-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\casestudies\\managing-invoicing-and-early-payments-in-the-telco-industry.js" /* webpackChunkName: "component---src-pages-casestudies-managing-invoicing-and-early-payments-in-the-telco-industry-js" */),
  "component---src-pages-contact-index-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\contact\\index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\news\\index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-services-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-index-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\team\\index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("E:\\Projects\\Lilardia Capital\\Website\\Latest\\src\\pages\\testimonials\\index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "E:\\Projects\\Lilardia Capital\\Website\\Latest\\.cache\\data.json")

